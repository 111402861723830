import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaFilm } from 'react-icons/fa'; // Importing the movie icon
import './mylist.css'; // Custom CSS for My List
import AdSense from '../components/AdSense'; // Import AdSense component

function MyList() {
  const [myList, setMyList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const storedList = JSON.parse(localStorage.getItem('myList')) || [];
    const sortedList = storedList.reverse(); // Sort by last added first
    setMyList(sortedList);
  }, []);

  const handleItemClick = (item) => {
    if (item.type === 'movie') {
      // Generate the slug for movies
      const releaseDate = item.release_date ? item.release_date.split('-')[0] : 'unknown'; // Fallback to 'unknown'
      const movieSlug = `${item.title.toLowerCase().replace(/[^a-z0-9]+/g, '-')}-${releaseDate}`;
      navigate(`/watch/movie/${movieSlug}`);
    } else if (item.type === 'tv') {
      // Generate the slug for TV shows
      const firstAirDate = item.first_air_date ? item.first_air_date.split('-')[0] : 'unknown'; // Fallback to 'unknown'
      const tvShowSlug = `${item.title.toLowerCase().replace(/[^a-z0-9]+/g, '-')}-${firstAirDate}`;
      navigate(`/watch/tv/${tvShowSlug}`);
    }
  };

  const handleRemoveClick = (id) => {
    const updatedList = myList.filter(item => item.id !== id);
    setMyList(updatedList);
    localStorage.setItem('myList', JSON.stringify(updatedList));
  };

  return (
    <div className="mylist-page">
      <h2 className="mylist-title">Your WatchList</h2>
      {myList.length > 0 ? (
        <>
          <div className="mylist-grid-container">
            {myList.map(item => (
              <div key={item.id} className="mylist-grid-item">
                <img
                  src={`https://image.tmdb.org/t/p/w500${item.poster_path}`}
                  alt={item.title || item.name}
                  className="mylist-grid-thumbnail"
                  onClick={() => handleItemClick(item)}
                />
                <div className="mylist-rating">{item.vote_average ? `${item.vote_average}/10` : 'N/A'}</div>
                <button 
                  className="mylist-remove-btn" 
                  onClick={() => handleRemoveClick(item.id)}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
          {/* Show ads below the list */}
          <AdSense />
        </>
      ) : (
        <div className="mylist-empty">
          <FaFilm className="mylist-empty-icon" /> {/* Movie Icon */}
          <p className="mylist-empty-text">No Items Found Here</p>
          {/* Show ads when the list is empty */}
          <AdSense />
        </div>
      )}
    </div>
  );
}

export default MyList;
