import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaYoutube, FaPlus, FaShareAlt, FaFilm } from 'react-icons/fa';
import './watch.css'; // Custom CSS for styling the Watch page
import AdSense from '../components/AdSense'; // Adjust the path accordingly

function Watch({ type }) {
  const { slug } = useParams(); // Get the content ID from the URL
  const [content, setContent] = useState(null); // Store the content details
  const [embedUrl, setEmbedUrl] = useState(''); // Store the URL for embedding the video
  const [loading, setLoading] = useState(true); // Loading state
  const [season, setSeason] = useState(1); // Default season for TV shows
  const [episode, setEpisode] = useState(1); // Default episode for TV shows
  const [maxEpisodes, setMaxEpisodes] = useState(1); // Max episodes for TV shows
  const [trailerUrl, setTrailerUrl] = useState(''); // Store the YouTube trailer URL
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal visibility
  const [isTvShow, setIsTvShow] = useState(false); // Check if it's a TV show
  const [activeTab, setActiveTab] = useState('episodes'); // Manage active tab
  const [isAddedToList, setIsAddedToList] = useState(false); // Track if the content is added to the list
  const [continueWatching, setContinueWatching] = useState(false); // Track if user should continue watching
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false); // Share popup state


  const episodeSectionRef = useRef(null); // For scrolling to episodes
  const navigate = useNavigate();

  const toggleSharePopup = () => {
    setIsSharePopupOpen(!isSharePopupOpen);
  };
  
  const handleCopyToClipboard = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText('https://delgor.com/')
        .then(() => {
          alert('Link copied to clipboard!');
        })
        .catch(err => {
          console.error('Could not copy text: ', err);
        });
    } else {
      // Fallback for mobile devices or browsers that do not support navigator.clipboard API
      const textArea = document.createElement('textarea');
      textArea.value = 'https://delgor.com';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        alert('Link copied to clipboard!');
      } catch (err) {
        console.error('Fallback: Could not copy text', err);
      }
      document.body.removeChild(textArea);
    }
  };
  
  useEffect(() => {
    const fetchContentDetails = async () => {
      setLoading(true);
      try {
        let response;
  
        const slugParts = slug.split('-');
        const year = slugParts.pop();
        const title = slugParts.join(' ').replace(/[^a-zA-Z0-9 ]/g, ''); // Clean title
  
        if (type === 'movie') {
          // Fetch movie details using TMDB API
          response = await axios.get(
            `https://api.themoviedb.org/3/search/movie?api_key=${process.env.REACT_APP_TMDB_API_KEY}&query=${title}&year=${year}`
          );
  
          const movie = response.data.results.length > 0 ? response.data.results[0] : null;
  
          if (movie) {
            // Fetch full movie details including videos
            const fullDetailsResponse = await axios.get(
              `https://api.themoviedb.org/3/movie/${movie.id}?api_key=${process.env.REACT_APP_TMDB_API_KEY}&append_to_response=videos`
            );
            const fullMovieDetails = fullDetailsResponse.data;
  
            // Extract trailer URL
            const trailers = fullMovieDetails.videos.results.filter(
              (video) => video.type === 'Trailer' && video.site === 'YouTube'
            );
            const trailerUrl = trailers.length > 0 ? `https://www.youtube.com/embed/${trailers[0].key}` : '';
            setTrailerUrl(trailerUrl);
  
            setContent(fullMovieDetails);
            setIsTvShow(false);
  
            setActiveTab('related');
  
            // Build embed URL using 2Embed
            const embedBaseUrl = process.env.REACT_APP_2EMBED_BASE_URL;
            const embedUrl = movie.imdb_id
              ? `${embedBaseUrl}/embed/${movie.imdb_id}`
              : movie?.id
              ? `${embedBaseUrl}/embed/${movie.id}`
              : '';
            setEmbedUrl(embedUrl);
  
            // Fetch recommendations
            fetchRecommendations(movie.id, 'movie');
            
            // Update meta description and title
            const metaDescription = `Watch ${fullMovieDetails.title} (${fullMovieDetails.release_date.split('-')[0]}) - ${fullMovieDetails.overview.slice(0, 150)}...`;
            document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);
            document.title = `${fullMovieDetails.title} | Delgor`;
            
            // Check if the content is already in the list
            const currentList = JSON.parse(localStorage.getItem('myList')) || [];
            const isAlreadyAdded = currentList.some((item) => item.id === fullMovieDetails.id);
            setIsAddedToList(isAlreadyAdded);
          } else {
            console.error('Movie not found');
          }
        } else if (type === 'tv') {
          // Use TMDB search API to find the TV show by title and year
          const tvShowSlugParts = slug.split('-');
          const tvShowTitle = tvShowSlugParts.slice(0, -1).join(' ').replace(/[^a-zA-Z0-9 ]/g, ''); // Clean title
          const tvShowYear = tvShowSlugParts[tvShowSlugParts.length - 1]; // Extract year
          response = await axios.get(
            `https://api.themoviedb.org/3/search/tv?api_key=${process.env.REACT_APP_TMDB_API_KEY}&query=${tvShowTitle}&first_air_date_year=${tvShowYear}`
          );
          
          const tvShow = response.data.results.length > 0 ? response.data.results[0] : null;
  
          if (tvShow) {
            // Fetch full TV show details including videos
            const fullDetailsResponse = await axios.get(
              `https://api.themoviedb.org/3/tv/${tvShow.id}?api_key=${process.env.REACT_APP_TMDB_API_KEY}&append_to_response=videos`
            );
            const fullTvShowDetails = fullDetailsResponse.data;
  
            // Extract trailer URL
            const trailers = fullTvShowDetails.videos.results.filter(
              (video) => video.type === 'Trailer' && video.site === 'YouTube'
            );
            const trailerUrl = trailers.length > 0 ? `https://www.youtube.com/embed/${trailers[0].key}` : '';
            setTrailerUrl(trailerUrl);
  
            setContent(fullTvShowDetails);
            setIsTvShow(true);
  
            // Fetch recommendations
            fetchRecommendations(tvShow.id, 'tv');
  
            // Update meta description and title
            const metaDescription = `Watch ${fullTvShowDetails.name} (${fullTvShowDetails.first_air_date.split('-')[0]}) - ${fullTvShowDetails.overview.slice(0, 150)}...`;
            document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);
            document.title = `${fullTvShowDetails.name} | Delgor`;
  
            // Fetch episodes for the current season
            fetchEpisodesForSeason(fullTvShowDetails.id, season);
  
            // Check if the content is already in the list
            const currentList = JSON.parse(localStorage.getItem('myList')) || [];
            const isAlreadyAdded = currentList.some((item) => item.id === fullTvShowDetails.id);
            setIsAddedToList(isAlreadyAdded);
  
            // Set the embed URL for the TV show
            const embedBaseUrl = process.env.REACT_APP_2EMBED_BASE_URL;
            const embedUrl = `${embedBaseUrl}/embedtv/${fullTvShowDetails.id}&s=${season}&e=${episode}`;
            setEmbedUrl(embedUrl);
          } else {
            console.error('TV show not found');
          }
        }
      } catch (error) {
        console.error('Error fetching content details:', error);
        setContent(null);
      } finally {
        setLoading(false);
      }
    };
  
    fetchContentDetails();
  }, [slug, type, season, episode]);
  

  useEffect(() => {
    if (content) {
      console.log('Content:', content);
      console.log('Number of seasons:', content?.number_of_seasons);
    }
  }, [content]);
  

  const fetchRecommendations = async (contentId, contentType) => {
    try {
      const response = await axios.get(
        `https://api.themoviedb.org/3/${contentType}/${contentId}/recommendations?api_key=${process.env.REACT_APP_TMDB_API_KEY}`
      );
      const recommendations = response.data.results;
      setContent((prevContent) => ({
        ...prevContent,
        recommendations: recommendations,
      }));
    } catch (error) {
      console.error('Error fetching recommendations:', error);
    }
  };

  const fetchEpisodesForSeason = async (tvId, seasonNumber) => {
    try {
      const seasonResponse = await axios.get(
        `https://api.themoviedb.org/3/tv/${tvId}/season/${seasonNumber}?api_key=${process.env.REACT_APP_TMDB_API_KEY}`
      );
      
      if (seasonResponse.data.episodes && seasonResponse.data.episodes.length > 0) {
        setMaxEpisodes(seasonResponse.data.episodes.length);
        setContent((prevContent) => ({
          ...prevContent,
          currentSeasonEpisodes: seasonResponse.data.episodes,
        }));
      } else {
        setContent((prevContent) => ({
          ...prevContent,
          currentSeasonEpisodes: [],
        }));
      }
    } catch (error) {
      console.error('Error fetching episodes for the selected season:', error);
    }
  };
  
  const trackLastWatched = (watchedContent) => {
    let lastWatched = JSON.parse(localStorage.getItem('lastWatched')) || [];
  
    // Remove the item if it already exists, to avoid duplication
    lastWatched = lastWatched.filter((item) => item.id !== watchedContent.id);
  
    // Add the new watched item to the beginning of the array
    lastWatched.unshift({
      id: watchedContent.id,
      title: watchedContent.title || watchedContent.name, // Ensure title is stored
      poster_path: watchedContent.poster_path,
      release_date: watchedContent.release_date, // Ensure release_date is stored
      first_air_date: watchedContent.first_air_date, // Ensure first_air_date is stored
      type: watchedContent.type,
    });
  
    // Ensure the array only contains up to 3 items
    if (lastWatched.length > 3) {
      lastWatched.pop();
    }
  
    // Save the updated array back to localStorage
    localStorage.setItem('lastWatched', JSON.stringify(lastWatched));
  };
  
  const handleSeasonChange = async (e) => {
    const selectedSeason = parseInt(e.target.value);
    setSeason(selectedSeason); // Update the state with the selected season
    setEpisode(1); // Reset to episode 1 when season changes
  };

  const handleContinueWatchingClick = () => {
    const watchProgress = JSON.parse(localStorage.getItem('watchProgress')) || {};
    const lastWatched = watchProgress[content.id];
  
    if (lastWatched) {
      // For movies, simply play from the last watched point
      if (!isTvShow) {
        setEmbedUrl(`${process.env.REACT_APP_2EMBED_BASE_URL}/embed/${content.id}`);
      } else {
        // For TV shows, play from the last saved season and episode
        setSeason(lastWatched.season);
        setEpisode(lastWatched.episode);
        setEmbedUrl(
          `${process.env.REACT_APP_2EMBED_BASE_URL}/embedtv/${content.id}&s=${lastWatched.season}&e=${lastWatched.episode}`
        );
      }
  
      setIsModalOpen(true);
    }
  };
  

  const handleAddToList = () => {
    const currentList = JSON.parse(localStorage.getItem('myList')) || [];
    const item = {
      id: content.id,
      title: content.title || content.name,
      poster_path: content.poster_path,
      type: type,
    };

    if (!isAddedToList) {
      currentList.push(item);
      localStorage.setItem('myList', JSON.stringify(currentList));
      setIsAddedToList(true);
    } else {
      const updatedList = currentList.filter((item) => item.id !== content.id);
      localStorage.setItem('myList', JSON.stringify(updatedList));
      setIsAddedToList(false);
    }
  };

  const handlePlayNowClick = () => {
    // Play from the currently selected season and episode
    const episodeToPlay = episode; // Use the current state, not saved progress

    // Set the embed URL based on whether it's a TV show or movie
    if (isTvShow) {
      setEmbedUrl(
        `https://www.2embed.cc/embedtv/${content.id}&s=${season}&e=${episodeToPlay}`
      );
    } else {
      setEmbedUrl(`${process.env.REACT_APP_2EMBED_BASE_URL}/embed/${content.id}`);
    }

    setIsModalOpen(true);

    // Store watch progress in local storage
    const watchProgress = JSON.parse(localStorage.getItem('watchProgress')) || {};
    watchProgress[content.id] = { season, episode: episodeToPlay };
    localStorage.setItem('watchProgress', JSON.stringify(watchProgress));

    // Track the last watched item for display later
    trackLastWatched({
      id: content.id,
      type,
      title: content.title || content.name,
      poster_path: content.poster_path,
    });
  };

  const handleEpisodeClick = (episodeNumber) => {
    setEpisode(episodeNumber); // Update the state with the selected episode
    setContinueWatching(false); // Disable continue watching since a new episode is selected

    // Automatically play the selected episode
    setEmbedUrl(
      `${process.env.REACT_APP_2EMBED_BASE_URL}/embedtv/${content.id}?s=${season}&e=${episodeNumber}`
    );
    setIsModalOpen(true);

    // Store watch progress for the selected episode
    const watchProgress = JSON.parse(localStorage.getItem('watchProgress')) || {};
    watchProgress[content.id] = { season, episode: episodeNumber };
    localStorage.setItem('watchProgress', JSON.stringify(watchProgress));

    // Track the last watched item
    trackLastWatched({
      id: content.id,
      type,
      title: content.title || content.name,
      poster_path: content.poster_path,
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleTrailerClick = () => {
    setEmbedUrl(trailerUrl); // Set the trailer URL as the embed URL
    setIsModalOpen(true); // Open the modal to show the trailer
  };

  const renderTvRecommendations = () => {
    return content?.recommendations?.map((item) => {
      const firstAirDate = item.first_air_date ? item.first_air_date.split('-')[0] : 'unknown';
      const tvShowSlug = `${item.name.toLowerCase().replace(/[^a-z0-9]+/g, '-')}-${firstAirDate}`;
  
      return (
        <div
          key={item.id}
          className="watch-page-grid-item"
          onClick={() => navigate(`/watch/tv/${tvShowSlug}`)}
        >
          <img
            src={`https://image.tmdb.org/t/p/w500${item.poster_path}`}
            alt={item.name}
            className="watch-page-grid-thumbnail"
          />
          <div className="watch-page-rating">{item.vote_average || 'N/A'}</div>
        </div>
      );
    });
  };
  
  const renderMovieRecommendations = () => {
    return content?.recommendations?.map((item) => {
      const releaseDate = item.release_date ? item.release_date.split('-')[0] : 'unknown';
      const movieSlug = `${item.title.toLowerCase().replace(/[^a-z0-9]+/g, '-')}-${releaseDate}`;
  
      return (
        <div
          key={item.id}
          className="watch-page-grid-item"
          onClick={() => navigate(`/watch/movie/${movieSlug}`)}
        >
          <img
            src={`https://image.tmdb.org/t/p/w500${item.poster_path}`}
            alt={item.title}
            className="watch-page-grid-thumbnail"
          />
          <div className="watch-page-rating">{item.vote_average || 'N/A'}</div>
        </div>
      );
    });
  };
  

  return (
    <div className="watch-page-container">
      {loading ? (
        <div className="loading-indicator">
          <FaFilm className="loading-icon" />
        </div>
      ) : content ? (
        <div>
          <div
            className="watch-page-hero"
            style={{
              backgroundImage: `url(https://image.tmdb.org/t/p/original${content.backdrop_path})`,
            }}
          >
            <div className="watch-page-hero-overlay"></div>
            <div className="watch-page-hero-content">
              <h1 className="watch-page-hero-title">
                {content.title || content.name}
              </h1>
              {isTvShow ? (
                <button
                  className="watch-page-hero-watch-btn"
                  onClick={continueWatching ? handleContinueWatchingClick : handlePlayNowClick}
                >
                  {continueWatching ? 'Continue Watching' : 'Play Now'}
                </button>
              ) : (
                <button
                  className="watch-page-hero-watch-btn"
                  onClick={handlePlayNowClick}
                >
                  Watch Now
                </button>
              )}
            </div>
          </div>

          <div className="watch-page-description">
            <p>{content.overview}</p>
          </div>

          <div className="watch-page-actions">
            <div className="watch-page-action-btn" onClick={handleTrailerClick}>
              <FaYoutube className="watch-page-action-icon" />
              <span>Trailer</span>
            </div>
            <div className="watch-page-action-btn" onClick={handleAddToList}>
              <FaPlus className="watch-page-action-icon" />
              <span>
                {isAddedToList ? 'Remove from List' : 'Add to List'}
              </span>
            </div>
            <div className="watch-page-action-btn" onClick={toggleSharePopup}>
  <FaShareAlt className="watch-page-action-icon" />
  <span>Share</span>
</div>

{/* Share Popup */}
{isSharePopupOpen && (
  <div className="share-popup">
    <button className="close-popup-btn" onClick={toggleSharePopup}>
      &times;
    </button>
    <h3>Sharing Is Caring</h3>
    <p>https://delgor.com</p>
    <button className="copy-btn" onClick={handleCopyToClipboard}>
      Copy to Clipboard
    </button>
    <span>Sharing Make Our Day</span>
  </div>
)}

          </div>
          <div className="watch-page-tabs-container">
            {isTvShow && (
              <div
                className={`watch-page-tab ${
                  activeTab === 'episodes' ? 'active' : ''
                }`}
                onClick={() => handleTabClick('episodes')}
              >
                Episodes
              </div>
            )}
            <div
              className={`watch-page-tab ${
                activeTab === 'related' ? 'active' : ''
              }`}
              onClick={() => handleTabClick('related')}
            >
              You May Also Like
            </div>
          </div>

          <div className="watch-page-tab-content">
            {activeTab === 'episodes' &&
            isTvShow &&
            content.currentSeasonEpisodes ? (
              <div
                className="watch-page-episodes-container"
                ref={episodeSectionRef}
              >
                <AdSense /> {/* Added AdSense above the season selector */}

                {content.number_of_seasons > 1 && (
                  <div className="watch-page-season-select">
                    <label htmlFor="season">Select Season:</label>
                    <select
                      id="season"
                      value={season}
                      onChange={handleSeasonChange}
                    >
                      {[...Array(content.number_of_seasons)].map(
                        (_, index) => (
                          <option key={index + 1} value={index + 1}>
                            Season {index + 1}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                )}


                <div className="watch-page-episodes-list">
                  {content.currentSeasonEpisodes.map((episode) => (
                    <div
                      key={episode.episode_number}
                      className="watch-page-episode-item"
                      onClick={() =>
                        handleEpisodeClick(episode.episode_number)
                      }
                    >
                      <div
                        className="watch-page-episode-thumbnail"
                        style={{
                          backgroundImage: `url(https://image.tmdb.org/t/p/w500${
                            episode.still_path || content.backdrop_path
                          })`,
                        }}
                      >
                        <div className="watch-page-episode-play-button">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="white"
                          >
                            <path d="M8 5v14l11-7z" />
                          </svg>
                        </div>
                      </div>
                      <div className="watch-page-episode-content">
                        <h3 className="watch-page-episode-title">
                          {episode.name || `Episode ${episode.episode_number}`}
                        </h3>
                        <div className="watch-page-episode-number">
                          Episode {episode.episode_number}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : activeTab === 'episodes' &&
              isTvShow &&
              !content.currentSeasonEpisodes ? (
              <p>No episodes found for this season.</p>
            ) : activeTab === 'related' &&
              content?.recommendations?.length > 0 ? (
              <div className="watch-page-related-content">
                <div className="watch-page-grid-container">
                  {isTvShow
                    ? renderTvRecommendations()
                    : renderMovieRecommendations()}
                </div>
                <AdSense /> {/* Insert AdSense below recommendations */}
              </div>
            ) : activeTab === 'related' ? (
              <AdSense />
            ) : null}
          </div>
          <AdSense /> {/* Added AdSense below the tab content */}

          {isModalOpen && (
            <div
              className="watch-page-modal-overlay"
              onClick={handleCloseModal}
            >
              <div
                className="watch-page-modal-content"
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  className="watch-page-modal-close-btn"
                  onClick={handleCloseModal}
                >
                  X
                </button>
                <iframe
                  width="100%"
                  height="500px"
                  src={embedUrl}
                  title="Watch Content"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <AdSense /> {/* Added AdSense inside the modal */}
            </div>
          )}
        </div>
      ) : (
        <div className="not-found-container">
  <div className="not-found-icon">
    <FaFilm className="not-found-film-icon" />
    <div className="icon-animation">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48" fill="currentColor">
        <path d="M9 3a9 9 0 110 18A9 9 0 019 3m0 2a7 7 0 100 14A7 7 0 009 5m1.3 4.29l5.6 5.6-1.41 1.41-5.6-5.6 1.41-1.41m5.6-2.18L14.6 7.88l-2.29 2.29 1.41 1.41 2.29-2.29z"></path>
      </svg>
    </div>
  </div>
  <h2 className="not-found-title">Oops! We couldn's make you watch this.</h2>
  <p className="not-found-description">Maybe it not released yet or it a reality show. Check back later we are working on it .</p>
  <button className="not-found-button" onClick={() => navigate('/')}>Take Me Home</button>
</div>

      )}
    </div>
  );
}

export default Watch;
