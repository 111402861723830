import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import './search.css'; // Ensure you have this CSS for the styles provided
import AdSense from '../components/AdSense'; // Adjust the path accordingly

function Search() {
  const [query, setQuery] = useState(''); // Search query
  const [results, setResults] = useState([]); // Search results
  const [popularContent, setPopularContent] = useState([]); // Popular content (movies & tv)
  const [similarResults, setSimilarResults] = useState([]); // Similar results in case of incorrect spelling
  const [isSearchSubmitted, setIsSearchSubmitted] = useState(false); // Track if the search has been submitted
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Extract query from URL if available (when navigating from homepage)
    const searchParams = new URLSearchParams(location.search);
    const queryParam = searchParams.get('query');
    if (queryParam) {
      setQuery(queryParam);
      handleSearch(queryParam);
    }
  }, [location.search]);

  useEffect(() => {
    const fetchPopularContent = async () => {
      try {
        const [popularMoviesResponse, popularTvShowsResponse] = await Promise.all([
          axios.get(`https://api.themoviedb.org/3/trending/movie/day?api_key=${process.env.REACT_APP_TMDB_API_KEY}`),
          axios.get(`https://api.themoviedb.org/3/trending/tv/day?api_key=${process.env.REACT_APP_TMDB_API_KEY}`),
        ]);
        const combinedContent = [
          ...popularMoviesResponse.data.results,
          ...popularTvShowsResponse.data.results,
        ];
        setPopularContent(combinedContent);
      } catch (error) {
        console.error('Error fetching popular content:', error);
      }
    };

    if (!query && !isSearchSubmitted) {
      fetchPopularContent(); // Fetch popular content if no search query and search hasn't been submitted
    }
  }, [query, isSearchSubmitted]);

  // Live search function with debounce
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (query) {
        handleSearch(query); // Perform live search as the user types
        setIsSearchSubmitted(false); // Reset search submission state for live updates
      } else {
        setResults([]); // Reset results when query is cleared
        setIsSearchSubmitted(false); // Reset search submission state
      }
    }, 500); // 500ms debounce for live search

    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  const handleSearch = async (searchQuery) => {
    try {
      const response = await axios.get(
        `https://api.themoviedb.org/3/search/multi?api_key=${process.env.REACT_APP_TMDB_API_KEY}&query=${searchQuery}`
      );
      
      const sortedResults = response.data.results.sort(
        (a, b) => (b.vote_average || 0) - (a.vote_average || 0)
      );

      setResults(sortedResults);

      // If no results found, fetch similar content based on keywords
      if (sortedResults.length === 0) {
        const similarResponse = await axios.get(
          `https://api.themoviedb.org/3/search/keyword?api_key=${process.env.REACT_APP_TMDB_API_KEY}&query=${searchQuery}`
        );
        const similarIds = similarResponse.data.results.map((item) => item.id);
        
        // Fetch movies based on similar keywords
        const similarContentResponse = await Promise.all(similarIds.map(id => 
          axios.get(`https://api.themoviedb.org/3/keyword/${id}/movies?api_key=${process.env.REACT_APP_TMDB_API_KEY}`)
        ));

        const similarMovies = similarContentResponse.flatMap(response => response.data.results);
        setSimilarResults(similarMovies);
      }

      setIsSearchSubmitted(true);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  // Handle "Enter" key press or "Search" button click
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (query.trim()) {
        navigate(`/search?query=${query.trim()}`);
        handleSearch(query.trim());
      }
    }
  };

  const handleItemClick = (item) => {
    if (item.media_type === 'movie') {
      // Create slug for movie
      const releaseDate = item.release_date ? item.release_date.split('-')[0] : 'unknown'; // Fallback to 'unknown'
      const movieSlug = `${item.title.toLowerCase().replace(/[^a-z0-9]+/g, '-')}-${releaseDate}`;
      navigate(`/watch/movie/${movieSlug}`);
    } else if (item.media_type === 'tv') {
      // Create slug for TV show
      const firstAirDate = item.first_air_date ? item.first_air_date.split('-')[0] : 'unknown'; // Fallback to 'unknown'
      const tvShowSlug = `${item.name.toLowerCase().replace(/[^a-z0-9]+/g, '-')}-${firstAirDate}`;
      navigate(`/watch/tv/${tvShowSlug}`);
    }
  };

  return (
    <div className="filtered-content-page">
      <form className="search-form" onSubmit={(e) => e.preventDefault()}>
        <input
          type="text"
          placeholder="Search for movies, TV shows..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={handleKeyDown} // Handle "Enter" key press for search submission
        />
      </form>
      
      <div className="grid-container">
        {isSearchSubmitted ? (
          results.length > 0 ? (
            results
              .filter((item) => item.poster_path) // Filter out items without an image
              .map((item) => (
                <div
                  key={item.id}
                  className="grid-item"
                  onClick={() => handleItemClick(item)}
                >
                  <img
                    src={`https://image.tmdb.org/t/p/w500${item.poster_path}`}
                    alt={item.title || item.name}
                    className="grid-thumbnail"
                  />
                  <div className="rating">{item.vote_average || 'N/A'}</div>
                </div>
              ))
          ) : similarResults.length > 0 ? (
            <>
              <h3>Did you mean:</h3>
              {similarResults
                .filter((item) => item.poster_path) // Filter out items without an image
                .map((item) => (
                  <div
                    key={item.id}
                    className="grid-item"
                    onClick={() => handleItemClick(item)}
                  >
                    <img
                      src={`https://image.tmdb.org/t/p/w500${item.poster_path}`}
                      alt={item.title || item.name}
                      className="grid-thumbnail"
                    />
                    <div className="rating">{item.vote_average || 'N/A'}</div>
                  </div>
                ))}
            </>
          ) : (
            <p>No results found.</p>
          )
        ) : (
          popularContent
            .filter((item) => item.poster_path) // Filter out items without an image
            .map((item) => (
              <div
                key={item.id}
                className="grid-item"
                onClick={() => handleItemClick(item)}
              >
                <img
                  src={`https://image.tmdb.org/t/p/w500${item.poster_path}`}
                  alt={item.title || item.name}
                  className="grid-thumbnail"
                />
                <div className="rating">{item.vote_average || 'N/A'}</div>
              </div>
            ))
        )}
      </div>

      <AdSense /> {/* Placed AdSense below the search grid */}
    </div>
  );
}

export default Search;
