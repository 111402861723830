import React, { useState, useEffect } from 'react';
import { FaTelegram, FaClipboard, FaList, FaInfoCircle, FaExclamationTriangle } from 'react-icons/fa';
import './more.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaDownload } from 'react-icons/fa';

function More() {
  const [trendingMovie, setTrendingMovie] = useState(null);
  const [copied, setCopied] = useState(false);
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
  const [isInWebView, setIsInWebView] = useState(false); // <-- Add this state
  const navigate = useNavigate();

  const shareLink = "https:delgor.com"; // Replace with your actual link

  useEffect(() => {
    const checkIfInWebView = () => {
      const userAgent = window.navigator.userAgent;
      const isWebApp = /(wv|WebView|AppCreate24)/gi.test(userAgent) || window.navigator.standalone;
      setIsInWebView(isWebApp); // <-- Set this state based on whether it's in web view or not
    };

    // Check if the app is in a WebView or AppCreate24
    checkIfInWebView();

    // Fetch trending movie from TMDB
    const fetchTrendingMovie = async () => {
      try {
        const response = await axios.get(`https://api.themoviedb.org/3/trending/movie/day?api_key=${process.env.REACT_APP_TMDB_API_KEY}`);
        setTrendingMovie(response.data.results[0]);
      } catch (error) {
        console.error('Error fetching trending movie:', error);
      }
    };

    fetchTrendingMovie();
  }, []);

  const handleCopy = () => {
    const textToCopy = shareLink;

    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "fixed";
      textArea.style.opacity = "0";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand("copy");
        if (successful) {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        } else {
          console.error("Fallback: Copy command was unsuccessful.");
        }
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }
      document.body.removeChild(textArea);
    }
  };

  return (
    <div className="more-page">
      {trendingMovie && (
        <div
          className="more-image"
          style={{
            backgroundImage: `url(https://image.tmdb.org/t/p/original${trendingMovie.backdrop_path})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'relative',
            height: '200px'
          }}
        >
          <div className="overlay"></div>
          <h1 className="movix-title">Delgor</h1>
        </div>
      )}

      <div className="share-feature">
        <h2>Tell People About Us</h2>
        <p>
          Hello, we are glad you're using MoviX. You can make our day if you share our app with your friends to
          enjoy unlimited movies and TV series for free.
        </p>
        <div className="share-link-container">
          <input type="text" value={shareLink} readOnly className="share-link-input" />
          <button className="copy-button" onClick={handleCopy}>
            {copied ? 'Copied!' : <FaClipboard />} Copy Link
          </button>
        </div>
      </div>

      <div className="more-links">
        <div className="more-link" onClick={() => navigate('/mylist')}>
          <FaList className="more-icon" /> My List
        </div>

        {/* Conditionally render the download app link */}
        {!isInWebView && (
          <div className="more-link" onClick={() => navigate('/download')}>
            <FaDownload className="more-icon" /> Download App
          </div>
        )}

        <div className="separator"></div>
        <div className="more-link" onClick={() => window.location.href = "https://t.me/delgormovies"}>
          <FaTelegram className="more-icon" /> Join Channel
        </div>
        <div className="separator"></div>
        <div className="more-link" onClick={() => setShowAboutModal(true)}>
          <FaInfoCircle className="more-icon" /> About Us
        </div>
        <div className="separator"></div>
        <div className="more-link" onClick={() => setShowDisclaimerModal(true)}>
          <FaExclamationTriangle className="more-icon" /> Disclaimer
        </div>
      </div>

      {showAboutModal && (
        <div className="modal-overlay" onClick={() => setShowAboutModal(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close-btn" onClick={() => setShowAboutModal(false)}>X</button>
            <h2>About Us</h2>
            <p>
              Delgor is your go-to app for all your favorite movies and TV series. We aim to provide a seamless experience
              for users who love entertainment. Share the app with your friends and let them enjoy it too!
            </p>
          </div>
        </div>
      )}

      {showDisclaimerModal && (
        <div className="modal-overlay" onClick={() => setShowDisclaimerModal(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close-btn" onClick={() => setShowDisclaimerModal(false)}>X</button>
            <h2>Disclaimer</h2>
            <p>
              Delgor does not host any of the media files on its servers. All content is provided by third-party services.
              We encourage you to verify the legality of the content with the respective hosts.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default More;
