import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/home';
import Movies from './pages/movies';
import TvShows from './pages/tvshows';
import Search from './pages/search';
import Watch from './pages/watch';
import FilteredMovies from './pages/filteredmovies';
import Navigation from './components/navigation';
import SeeAll from './pages/seeall';
import MyList from './pages/mylist';
import More from './pages/more';
import SplashScreen from './components/SplashScreen'; // Updated SplashScreen without props
import DesktopNavigation from './components/desktopNavigation'; // Import the new desktop navigation
import Download from './pages/download'; // Import the Download page




function App() {
  return (
    <Router>
      <SplashAndRoutes />
    </Router>
  );
}

function SplashAndRoutes() {
  const [loadingComplete, setLoadingComplete] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Show splash screen only on the home page for 5 seconds
    if (location.pathname === "/") {
      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 5000); // 5 seconds timer for splash screen
      return () => clearTimeout(timer); // Clear timer on unmount
    } else {
      setLoadingComplete(true); // Immediately load content for other routes
    }
  }, [location.pathname]);

  return (
    <>
      {!loadingComplete && location.pathname === "/" && <SplashScreen />}
      {loadingComplete && (
        <>
          <div className="app-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/movies" element={<Movies />} />
              <Route path="/tvshows" element={<TvShows />} />
              <Route path="/search" element={<Search />} />
              <Route path="/watch/movie/:slug" element={<Watch type="movie" />} />
              <Route path="/watch/tv/:slug" element={<Watch type="tv" />} />
              <Route path="/watch/anime/:slug" element={<Watch type="anime" />} />
              <Route path="/filter/:type/:id" element={<FilteredMovies />} />
              <Route path="/see-all/:type" element={<SeeAll />} />
              <Route path="/mylist" element={<MyList />} />
              <Route path="/more" element={<More />} />
              <Route path="/download" element={<Download />} /> {/* New Download route */}

            </Routes>
          </div>
          <Navigation />
          <DesktopNavigation /> {/* Desktop Navigation */}

        </>
      )}
    </>
  );
}

export default App;
