import React from 'react';
import { FaDownload } from 'react-icons/fa'; // Import the download icon
import './download.css'; // Ensure this CSS file contains the styling

function Download() {
  return (
    <div className="download-page">
      <div className="download-content">
        <div className="animated-line-container">
          {/* Moving line effect */}
          <div className="moving-line"></div>
        </div>
        <div className="download-left">
          <h1 className="download-title">Get the Delgor App</h1>
          <p className="download-description">
            Experience Delgor like never before with our Android app! Streamlined, fast, and loaded with the features you love.
          </p>
          <ul className="download-benefits">
            <li>Futuristic UI with intuitive controls.</li>
            <li>Lightning-fast movie and TV show search.</li>
            <li>Customized suggestions tailored to your taste.</li>
            <li>Offline support – watch on the go!</li>
            <li>Daily updates with new content.</li>
          </ul>
          <div className="download-buttons">
            <a href="https://delgor.com/delgor.apk" className="download-btn">
              <FaDownload className="download-icon" /> Download APK
            </a>
          </div>
        </div>
        <div className="download-right">
        <img src={`${process.env.PUBLIC_URL}/download.png`} alt="App Preview" />
        </div>
        <div className="animated-line-container">
          {/* Moving line effect */}
          <div className="moving-line"></div>
        </div>
      </div>
    </div>
  );
}

export default Download;
