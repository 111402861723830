import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './filteredmovies.css';
import { FaFilm } from 'react-icons/fa'; // Import the movie icon
import AdSense from '../components/AdSense'; // Adjust the path accordingly


function FilteredMovies() {
  const { type, id } = useParams();
  const [content, setContent] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // Total pages fetched from API
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const navigate = useNavigate();

  const itemsPerPage = 30;

  useEffect(() => {
    const fetchContent = async () => {
      setLoading(true);
      setError(null); // Reset error state

      try {
        const contentEndpoint =
          type === 'network'
            ? `https://api.themoviedb.org/3/discover/movie?api_key=${process.env.REACT_APP_TMDB_API_KEY}&with_networks=${id}&page=${currentPage}`
            : `https://api.themoviedb.org/3/discover/movie?api_key=${process.env.REACT_APP_TMDB_API_KEY}&with_companies=${id}&page=${currentPage}`;

        const tvEndpoint =
          type === 'network'
            ? `https://api.themoviedb.org/3/discover/tv?api_key=${process.env.REACT_APP_TMDB_API_KEY}&with_networks=${id}&page=${currentPage}`
            : `https://api.themoviedb.org/3/discover/tv?api_key=${process.env.REACT_APP_TMDB_API_KEY}&with_companies=${id}&page=${currentPage}`;

        const [moviesResponse, tvResponse] = await Promise.all([
          axios.get(contentEndpoint),
          axios.get(tvEndpoint),
        ]);

        const combinedContent = [
          ...moviesResponse.data.results,
          ...tvResponse.data.results,
        ];

        setContent(combinedContent);
        setTotalPages(Math.max(moviesResponse.data.total_pages, tvResponse.data.total_pages)); // Set total pages based on API response
      } catch (error) {
        setError('Error fetching content');
      } finally {
        setLoading(false);
      }
    };

    fetchContent();
  }, [type, id, currentPage]);

  const handleItemClick = (item) => {
    if (item.media_type === 'movie' || item.title) {
      const releaseDate = item.release_date ? item.release_date.split('-')[0] : 'unknown'; // Fallback to 'unknown'
      const movieSlug = `${item.title.toLowerCase().replace(/[^a-z0-9]+/g, '-')}-${releaseDate}`;
      navigate(`/watch/movie/${movieSlug}`);
    } else if (item.media_type === 'tv' || item.name) {
      const firstAirDate = item.first_air_date ? item.first_air_date.split('-')[0] : 'unknown'; // Fallback to 'unknown'
      const tvShowSlug = `${item.name.toLowerCase().replace(/[^a-z0-9]+/g, '-')}-${firstAirDate}`;
      navigate(`/watch/tv/${tvShowSlug}`);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderPagination = () => {
    const pageButtons = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          className={`page-button ${currentPage === i ? 'active' : ''}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    return pageButtons;
  };

  return (
    <div className="filtered-content-page">
      {loading ? (
        <div className="loading-indicator">
          <FaFilm className="loading-icon" />
        </div>
      ) : error ? (
        <div className="error-message">
          <FaFilm className="error-icon" />
          <p>{error}</p>
        </div>
      ) : content.length > 0 ? (
        <>
          <AdSense />

          <div className="grid-container">
            {content.map((item) => (
              <div
                key={item.id}
                className="grid-item"
                onClick={() => handleItemClick(item)}
              >
                <img
                  src={`https://image.tmdb.org/t/p/w500${item.poster_path}`}
                  alt={item.title || item.name}
                  className="grid-thumbnail"
                />
                <div className="rating">{item.vote_average || 'N/A'}</div>
              </div>
            ))}
          </div>
          <AdSense />
          <div className="pagination">
            {currentPage > 1 && (
              <button
                className="page-button"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>
            )}
            {renderPagination()}
            {currentPage < totalPages && (
              <button
                className="page-button"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            )}
          </div>
        </>
      ) : (
        <div className="no-content">
          <FaFilm className="no-content-icon" />
          <p>No content found.</p>
        </div>
        
      )}
    </div>
    
  );
}

export default FilteredMovies;
