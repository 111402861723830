import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaFilm, FaTv, FaSearch, FaList } from 'react-icons/fa'; // Import the appropriate icons
import './navigation.css';

function Navigation() {
  return (
    <nav className="futuristic-navigation">
      <Link to="/movies" className="nav-button futuristic-button">
        <FaFilm size={24} />
        <span>Movies</span>
      </Link>
      <Link to="/tvshows" className="nav-button futuristic-button">
        <FaTv size={24} />
        <span>TV Shows</span>
      </Link>
      <Link to="/" className="nav-button futuristic-button">
        <FaHome size={24} />
        <span>Home</span>
      </Link>
      <Link to="/search" className="nav-button futuristic-button">
        <FaSearch size={24} />
        <span>Search</span>
      </Link>
      <Link to="/more" className="nav-button futuristic-button">
        <FaList size={24} />
        <span>More</span>
      </Link>
    </nav>
  );
}

export default Navigation;
