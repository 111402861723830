import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './seeall.css'; // Custom CSS for styling the See All page
import { FaFilm } from 'react-icons/fa'; // Import the movie icon
import AdSense from '../components/AdSense'; // Adjust the path accordingly

function SeeAll() {
  const { type } = useParams(); // Get the content type (movies or tv) from the URL
  const [content, setContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // Keep track of the current page
  const [totalPages, setTotalPages] = useState(1); // Keep track of total pages
  const navigate = useNavigate(); // For navigating to the watch page

  useEffect(() => {
    const fetchContent = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/trending/${type}/day?api_key=${process.env.REACT_APP_TMDB_API_KEY}&page=${currentPage}`
        );
        setContent(response.data.results);
        setTotalPages(response.data.total_pages); // Update total pages
      } catch (error) {
        console.error('Error fetching content:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchContent();
  }, [type, currentPage]); // Fetch content every time the page or type changes

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Navigate to the watch page with slug generation
  const handleItemClick = (item) => {
    if (type === 'movie') {
      const releaseDate = item.release_date ? item.release_date.split('-')[0] : 'unknown';
      const movieSlug = `${item.title.toLowerCase().replace(/[^a-z0-9]+/g, '-')}-${releaseDate}`;
      navigate(`/watch/movie/${movieSlug}`);
    } else if (type === 'tv') {
      const firstAirDate = item.first_air_date ? item.first_air_date.split('-')[0] : 'unknown';
      const tvShowSlug = `${item.name.toLowerCase().replace(/[^a-z0-9]+/g, '-')}-${firstAirDate}`;
      navigate(`/watch/tv/${tvShowSlug}`);
    }
  };

  // Generate pagination buttons with a range of 5 pages
  const renderPagination = () => {
    const pageButtons = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          className={`page-button ${currentPage === i ? 'active' : ''}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    return pageButtons;
  };

  return (
    <div className="seeall-page">
      {loading ? (
        <div className="loading-indicator">
          <FaFilm className="loading-icon" />
        </div>
      ) : (
        <>
          <div className="grid-container">
            {content.map((item) => (
              <div
                key={item.id}
                className="grid-item"
                onClick={() => handleItemClick(item)}
              >
                <img
                  src={`https://image.tmdb.org/t/p/w500${item.poster_path}`}
                  alt={item.title || item.name}
                  className="grid-thumbnail"
                />
                <div className="rating">{item.vote_average || 'N/A'}</div>
              </div>
            ))}
          </div>

          {/* Pagination */}
          <div className="pagination">
            {currentPage > 1 && (
              <button
                className="page-button"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>
            )}
            {renderPagination()}
            {currentPage < totalPages && (
              <button
                className="page-button"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            )}
          </div>
          <AdSense />
        </>
      )}
    </div>
  );
}

export default SeeAll;
