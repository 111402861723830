import React from 'react';
import { Link } from 'react-router-dom';
import { FaTelegram, FaDownload } from 'react-icons/fa';
import './desktopNavigation.css'; // Custom CSS for the desktop navigation

function DesktopNavigation() {
  return (
    <header className="desktop-nav">
      <div className="nav-left">
        <Link to="/" className="desktop-logo">
          Delgor
        </Link>
      </div>
      <div className="nav-right">
        <Link to="/" className="desktop-nav-button">
          Home
        </Link>
        <Link to="/movies" className="desktop-nav-button">
          Movies
        </Link>
        <Link to="/tvshows" className="desktop-nav-button">
          TV Shows
        </Link>
        <Link to="/mylist" className="desktop-nav-button">
          My List
        </Link>
        <Link to="/more" className="desktop-nav-button">
          More
        </Link>

        {/* Telegram Icon and Link */}
        <a href="https://t.me/delgormovies" className="desktop-nav-button">
          <FaTelegram style={{ marginRight: '8px' }} />
        </a>

        {/* Download App Button */}
        <Link to="/download" className="desktop-nav-button download-button">
          <FaDownload style={{ marginRight: '8px' }} /> Download APP
        </Link>
      </div>
    </header>
  );
}

export default DesktopNavigation;
