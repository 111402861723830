import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './home.css';
import { useNavigate } from 'react-router-dom'; // For navigation
import { FaPlus, FaList, FaPlay, FaDownload } from 'react-icons/fa';
import AdSense from '../components/AdSense'; // Adjust the path accordingly

function Home() {
  const [movies, setMovies] = useState([]);
  const [tvShows, setTvShows] = useState([]);
  const [recentMovies, setRecentMovies] = useState([]); // New state for recent movies grid
  const [heroMovie, setHeroMovie] = useState(null);
  const [networks, setNetworks] = useState([]);
  const [companies, setCompanies] = useState([]); // New state for production companies
  const [continueWatching, setContinueWatching] = useState([]); // State to store last 3 watched items
  const [query, setQuery] = useState('');
  const [showPopup, setShowPopup] = useState(false); // State for controlling the popup

  const navigate = useNavigate(); // For navigating to the search and watch pages

  // Handle Search outside of the useEffect
  const handleSearch = (e) => {
    e.preventDefault(); // Prevent form submission
    if (query.trim()) {
      navigate(`/search?query=${query.trim()}`); // Redirect to search page with query
    }
  };

  // Close the download popup
  const handleClose = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/trending/movie/day?api_key=${process.env.REACT_APP_TMDB_API_KEY}`
        );
        setMovies(response.data.results);
        const randomIndex = Math.floor(Math.random() * response.data.results.length);
        setHeroMovie(response.data.results[randomIndex]);
      } catch (error) {
        console.error('Error fetching trending movies:', error);
      }
    };

    const fetchTvShows = async () => {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/trending/tv/day?api_key=${process.env.REACT_APP_TMDB_API_KEY}`
        );
        setTvShows(response.data.results);
      } catch (error) {
        console.error('Error fetching trending TV shows:', error);
      }
    };

    const isInWebView = () => {
      const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
      const standalone = window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
    
      // Check if the app is running in a WebView or standalone mode
      return /(wv|WebView|AppCreate24)/gi.test(userAgent) || standalone;
    };
    
    // Fetch recent movies for the grid
    const fetchRecentMovies = async () => {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/movie/now_playing?api_key=${process.env.REACT_APP_TMDB_API_KEY}`
        );
        setRecentMovies(response.data.results.slice(0, 25)); // Limiting to 25 most recent movies
      } catch (error) {
        console.error('Error fetching recent movies:', error);
      }
    };

    // Fetch networks dynamically from TMDB
    const fetchNetworks = async () => {
      const networkIds = [213, 1024, 49, 2739, 453, 174, 521, 5, 273, 420, 2, 21, 3]; // Popular network IDs
      try {
        const networkPromises = networkIds.map(id =>
          axios.get(`https://api.themoviedb.org/3/network/${id}?api_key=${process.env.REACT_APP_TMDB_API_KEY}`)
        );
        const networkResponses = await Promise.all(networkPromises);
        const networkData = networkResponses.map(response => response.data);
        setNetworks(networkData);
      } catch (error) {
        console.error('Error fetching networks:', error);
      }
    };

    // Fetch popular production companies dynamically from TMDB
    const fetchCompanies = async () => {
      const companyIds = [420, 429, 174, 33, 4, 34, 25, 5, 3, 1632]; // Popular production company IDs
      try {
        const companyPromises = companyIds.map(id =>
          axios.get(`https://api.themoviedb.org/3/company/${id}?api_key=${process.env.REACT_APP_TMDB_API_KEY}`)
        );
        const companyResponses = await Promise.all(companyPromises);
        const companyData = companyResponses.map(response => response.data);
        setCompanies(companyData); // Store the fetched companies in state
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    // Fetch last 3 watched items from localStorage
    const fetchContinueWatching = () => {
      const lastWatched = JSON.parse(localStorage.getItem('lastWatched')) || [];
      setContinueWatching(lastWatched.slice(0, 3)); // Always update with the latest 3 items
    };

    fetchMovies();
    fetchTvShows();
    fetchRecentMovies(); // Fetch recent movies for the grid
    fetchNetworks();
    fetchCompanies(); // Fetch production companies
    fetchContinueWatching(); // Fetch the last watched items
    

    // Show the download popup only when in the browser (not in a web app)
    const isWebApp = isInWebView();

  if (!isWebApp) {
    setTimeout(() => {
      setShowPopup(true); // Show the popup after a delay
    }, 8000); // 3 seconds delay before showing popup
    }
  }, []);

  // Handle adding hero movie to "My List"
  const handleAddToList = () => {
    const currentList = JSON.parse(localStorage.getItem('myList')) || [];
    const isAlreadyAdded = currentList.some(item => item.id === heroMovie.id);

    if (!isAlreadyAdded) {
      const newItem = {
        id: heroMovie.id,
        title: heroMovie.title,
        poster_path: heroMovie.poster_path,
        type: 'movie',
      };
      currentList.push(newItem);
      localStorage.setItem('myList', JSON.stringify(currentList));
      alert(`${heroMovie.title} has been added to your list!`);
    } else {
      alert(`${heroMovie.title} is already in your list!`);
    }
  };

  const handleMovieClick = (movie) => {
    if (movie && movie.title) {
      const releaseDate = movie.release_date ? movie.release_date.split('-')[0] : 'unknown'; // Fallback to 'unknown'
      const movieSlug = `${movie.title.toLowerCase().replace(/[^a-z0-9]+/g, '-')}-${releaseDate}`;
      navigate(`/watch/movie/${movieSlug}`); // Navigate to the movie watch page
    } else {
      console.error('Movie title or release date is undefined or invalid', movie);
    }
  };
  
  
  
  const handleTvShowClick = (tvShow) => {
    if (tvShow && tvShow.name) {
      const firstAirDate = tvShow.first_air_date ? tvShow.first_air_date.split('-')[0] : 'unknown'; // Fallback to 'unknown'
      const tvShowSlug = `${tvShow.name.toLowerCase().replace(/[^a-z0-9]+/g, '-')}-${firstAirDate}`;
      navigate(`/watch/tv/${tvShowSlug}`); // Navigate to the TV show watch page
    } else {
      console.error('TV show name or first air date is undefined or invalid', tvShow);
    }
  };
  
  
  
  const handleRemoveFromContinueWatching = (id) => {
    const updatedContinueWatching = continueWatching.filter(item => item.id !== id);
    setContinueWatching(updatedContinueWatching);
    localStorage.setItem('lastWatched', JSON.stringify(updatedContinueWatching)); // Update localStorage
  };

  const handleNetworkClick = (networkId) => {
    navigate(`/filter/network/${networkId}`); // Correct URL
  };

  const handleCompanyClick = (companyId) => {
    navigate(`/filter/company/${companyId}`); // Correct URL
  };

  const handleSeeAllMovies = () => {
    navigate('/see-all/movie'); // Navigate to the See All Movies page
  };

  const handleSeeAllTvShows = () => {
    navigate('/see-all/tv'); // Navigate to the See All TV Shows page
  };

  return (
    <div className="home-page">
       {/* Banner Section for PC using Most Trending Movie */}
       {heroMovie && (
        <div
          className="home-page-banner"
          style={{
            backgroundImage: `url(https://image.tmdb.org/t/p/original${heroMovie.backdrop_path})`,
          }}
        >
          <div className="home-page-banner-overlay">
            <div className="home-page-banner-content">
              <h1>Watch Movies, TV Shows, and More</h1>
              <div className="home-page-search-bar">
                <form onSubmit={handleSearch}>
                  <input 
                    type="text" 
                    placeholder="Search for movies or TV shows..." 
                    value={query}
                    onChange={(e) => setQuery(e.target.value)} 
                  />
                  <button type="submit">Search</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Hero Section */}
      {heroMovie && (
        <div
          className="home-page-hero"
          style={{
            backgroundImage: `url(https://image.tmdb.org/t/p/original${heroMovie.backdrop_path})`,
          }}
        >
          <div className="home-page-hero-overlay">
            <div className="home-page-hero-content">
              <h2>{heroMovie.title}</h2>
              <div className="home-page-hero-buttons" style={{ justifyContent: 'center' }}>
                <button className="home-page-hero-btn" onClick={handleAddToList}>
                  <FaPlus style={{ marginRight: '8px' }} />Add List
                </button>
                <button className="home-page-hero-btn watch-btn" onClick={() => handleMovieClick(heroMovie)}>
                <FaPlay style={{ marginRight: '8px' }} /> Watch
              </button>
                <button className="home-page-hero-btn" onClick={() => navigate('/mylist')}>
                  <FaList style={{ marginRight: '8px' }} /> My List
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

     {/* Continue Watching Section */}
{continueWatching.length > 0 && (
  <div className="home-page-continue-watching-section">
    <div className="home-page-continue-watching-header">
      <h2 className="home-page-continue-watching-title">Continue Watching</h2>
    </div>
    <div className="home-page-continue-watching-grid">
      {continueWatching.map((item) => (
        <div 
          key={item.id} 
          className="home-page-continue-watching-item"
          onClick={() => item.type === 'movie' ? handleMovieClick(item) : handleTvShowClick(item)} // Ensure TV shows are clickable
        >
          <img
            src={`https://image.tmdb.org/t/p/w500${item.poster_path}`}
            alt={item.title || item.name} // Handle both movie and TV show titles
            className="home-page-continue-watching-thumbnail"
          />
          <div className="home-page-continue-watching-remove-container">
            <button 
              className="home-page-continue-watching-remove-btn" 
              onClick={(e) => {
                e.stopPropagation(); // Prevent the click event from bubbling to the parent
                handleRemoveFromContinueWatching(item.id);
              }}
            >
              Remove
            </button>
          </div>
        </div>
      ))}
    </div>
  </div>
)}


      {/* Trending TV Shows */}
      <div className="home-page-section">
        <h2 className="home-page-section-title">Trending Shows</h2>
        <a onClick={handleSeeAllTvShows} className="home-page-see-all-link">See All</a>
      </div>
      <div className="home-page-slider-container">
      {tvShows.slice(0, 25).map(show => (
        <div key={show.id} className="home-page-slider-item" onClick={() => handleTvShowClick(show)}>
          <img
            src={`https://image.tmdb.org/t/p/w500${show.poster_path}`}
            alt={show.name}
            className="home-page-slider-thumbnail"
          />
          <div className="home-page-rating">{show.vote_average || 'N/A'}</div>
          </div>
        ))}
      </div>
      <AdSense />

      {/* Trending Movies */}
      <div className="home-page-section">
        <h2 className="home-page-section-title">Trending Movies</h2>
        <a onClick={handleSeeAllMovies} className="home-page-see-all-link">See All</a>
      </div>
      <div className="home-page-slider-container">
      {movies.slice(0, 25).map(movie => (
  <div key={movie.id} className="home-page-slider-item" onClick={() => handleMovieClick(movie)}>
    <img
      src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
      alt={movie.title}
      className="home-page-slider-thumbnail"
    />
    <div className="home-page-rating">{movie.vote_average || 'N/A'}</div>
          </div>
        ))}
      </div>

      {/* Network Selection */}
      <div className="home-page-section">
        <h2 className="home-page-section-title">Networks</h2>
      </div>
      <div className="home-page-network-slider-container">
        {networks.map(network => (
          <div key={network.id} className="home-page-network-slider-item" onClick={() => handleNetworkClick(network.id)}>
            <img
              src={`https://image.tmdb.org/t/p/original${network.logo_path}`} 
              alt={network.name}
              className="home-page-network-logo"
            />
          </div>
        ))}
      </div>

      {/* Popular Production Companies */}
      <div className="home-page-section">
        <h2 className="home-page-section-title">Production Companies</h2>
      </div>
      <div className="home-page-company-slider-container">
        {companies.map(company => (
          <div key={company.id} className="home-page-company-slider-item" onClick={() => handleCompanyClick(company.id)}>
            <img
              src={`https://image.tmdb.org/t/p/original${company.logo_path}`} 
              alt={company.name}
              className="home-page-company-logo"
            />
          </div>
        ))}
      </div>
        
      <AdSense />
      {/* Recent Movies Grid */}
      <div className="home-page-section">
        <h2 className="home-page-section-title">Recent Movies</h2>
      </div>
      <div className="home-page-grid-container">
      {recentMovies.slice(0, 20).map((movie) => (
          <div
            key={movie.id}
            className="home-page-grid-item"
            onClick={() => handleMovieClick(movie)}
          >
            <img
              src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
              alt={movie.title}
              className="home-page-grid-thumbnail"
            />
            <div className="home-page-rating">{movie.vote_average || 'N/A'}</div>
          </div>
        ))}
      </div>
      
      <AdSense />

      {/* Download App Popup */}
      {showPopup && (
        <div className="download-popup">
          <div className="download-popup-content">
            <h2>Download Our App</h2>
            <p>Experience Delgor in a faster and more streamlined way with our app!</p>
            <a href="https://delgor.com/delgor.apk" className="download-btn">
              <FaDownload className="download-icon" />
              Download APK
            </a>
            <button className="close-popup-btn" onClick={handleClose}>
              X
            </button>
          </div>
        </div>
      )}

    </div>
  );
}

export default Home;
