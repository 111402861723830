import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './tvshows.css'; // Custom CSS for styling the TV Shows page
import AdSense from '../components/AdSense'; // Adjust the path accordingly

function TvShows() {
  const [tvShows, setTvShows] = useState([]);
  const [genres, setGenres] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(20); // Limiting to 20 total pages
  const itemsPerPage = 30; // TV shows per page
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTvShows = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/discover/tv?api_key=${process.env.REACT_APP_TMDB_API_KEY}${
            selectedGenre ? `&with_genres=${selectedGenre}` : ''
          }&page=${currentPage}`
        );
        setTvShows(response.data.results);
      } catch (error) {
        console.error('Error fetching TV shows:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchGenres = async () => {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/genre/tv/list?api_key=${process.env.REACT_APP_TMDB_API_KEY}`
        );
        setGenres(response.data.genres);
      } catch (error) {
        console.error('Error fetching genres:', error);
      }
    };

    fetchTvShows();
    fetchGenres();
  }, [selectedGenre, currentPage]);

  // Handle navigation to the watch page
  const handleTvShowClick = (show) => {
    // Generate a slug using the TV show name and first air date year
    if (show && show.name) {
      const firstAirDate = show.first_air_date ? show.first_air_date.split('-')[0] : 'unknown'; // Fallback to 'unknown'
      const tvShowSlug = `${show.name.toLowerCase().replace(/[^a-z0-9]+/g, '-')}-${firstAirDate}`;
      navigate(`/watch/tv/${tvShowSlug}`); // Navigate to the TV show watch page
    } else {
      console.error('TV show name or first air date is undefined or invalid', show);
    }
  };

  // Handle genre change
  const handleGenreChange = (e) => {
    setSelectedGenre(e.target.value);
    setCurrentPage(1); // Reset to page 1 when genre changes
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // Generate pagination numbers (4 around the current page)
  const generatePagination = () => {
    const pages = [];
    const paginationRange = 2; // Two pages on either side of the current page

    const startPage = Math.max(1, currentPage - paginationRange);
    const endPage = Math.min(totalPages, currentPage + paginationRange);

    // Add the pages within the range
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  return (
    <div className="tvshows-page">
      <div className="tvshows-header">
        <h1 className="tvshows-title">TV Shows</h1>
        <div className="genre-filter">
          <label htmlFor="genre"></label>
          <select id="genre" onChange={handleGenreChange}>
            <option value="">Select Genre</option>
            {genres.map((genre) => (
              <option key={genre.id} value={genre.id}>
                {genre.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <AdSense />
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className="grid-container">
            {tvShows.map((show) => (
              <div
                key={show.id}
                className="grid-item"
                onClick={() => handleTvShowClick(show)}
              >
                <img
                  src={`https://image.tmdb.org/t/p/w500${show.poster_path}`}
                  alt={show.name}
                  className="grid-thumbnail"
                />
                <div className="rating">{show.vote_average || 'N/A'}</div>
              </div>
            ))}
          </div>
          <AdSense />
          {/* Pagination */}
          <div className="pagination">
            {generatePagination().map((page) => (
              <button
                key={page}
                className={`page-button ${currentPage === page ? 'active' : ''}`}
                onClick={() => handlePageChange(page)}
              >
                {page}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default TvShows;
