import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './SplashScreen.css'; // Import the custom styles

function SplashScreen() {
  const [randomImage, setRandomImage] = useState(null);
  const [showSplash, setShowSplash] = useState(true); // Manage splash visibility

  useEffect(() => {
    const fetchTrendingMedia = async () => {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/trending/all/day?api_key=${process.env.REACT_APP_TMDB_API_KEY}`
        );
        const trendingMedia = response.data.results.slice(0, 5); // Get top 5 trending media
        const randomIndex = Math.floor(Math.random() * trendingMedia.length);
        setRandomImage(
          `https://image.tmdb.org/t/p/original${trendingMedia[randomIndex].backdrop_path}`
        ); // Set the correct image URL
      } catch (error) {
        console.error('Error fetching trending media:', error);
      }
    };

    fetchTrendingMedia();

    // Hide the splash screen after 5 seconds
    const timer = setTimeout(() => {
      setShowSplash(false); // Hide the splash screen
    }, 5000);

    return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
  }, []);

  if (!showSplash) return null; // Render nothing when splash is not shown

  return (
    <div
      className="splash-screen"
      style={{
        backgroundImage: randomImage ? `url(${randomImage})` : 'none',
      }}
    >
      <div className="splash-overlay"></div> {/* Red overlay */}
      <h1 className="splash-title">Delgor</h1>
      <div className="loading-bar-container">
        <div className="loading-bar"></div> {/* Animated loading bar */}
      </div>
    </div>
  );
}

export default SplashScreen;
